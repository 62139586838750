@charset "UTF-8";
/*------------------------------------*\
    $RESET
\*------------------------------------*/
/* Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/ */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
footer,
nav,
section,
article,
hgroup,
figure {
  display: block;
}

ul {
  list-style: none;
}

/* Force Firefox to have an initial height */
[contenteditable]::before {
  content: "﻿";
  display: inline-block;
  width: 0;
}

a img {
  border-width: 0;
}

/*------------------------------------*\
    $RESET
\*------------------------------------*/
/* Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/ */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
footer,
nav,
section,
article,
hgroup,
figure {
  display: block;
}

ul {
  list-style: none;
}

/* Force Firefox to have an initial height */
[contenteditable]::before {
  content: "﻿";
  display: inline-block;
  width: 0;
}

a img {
  border-width: 0;
}

/*
 Naming Convention Details:
  Color variable name is determined by the K value from a color's corresponding CMYK value.
  i.e.  #F7F7F7 'K' value from it's corresponding CMYK value (0%, 0%, 0%, 3%) is '3'; therefore the variable name assigned is '$color-gray-03'
  Any anomalies (different tints of c m y) should be left out of the generic file and placed into Vertical specific file as necessary
 */
/*------------------------------------*\
    $RESET
\*------------------------------------*/
/* Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/ */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header,
footer,
nav,
section,
article,
hgroup,
figure {
  display: block;
}

ul {
  list-style: none;
}

/* Force Firefox to have an initial height */
[contenteditable]::before {
  content: "﻿";
  display: inline-block;
  width: 0;
}

a img {
  border-width: 0;
}

:root {
  --mg-font-primary: Georgia, Cambria, "Times New Roman", Times, serif;
  --mg-font-secondary: "Lucida Grande", Tahoma, Sans-Serif;
  --mg-font-family-lion: var(--mg-font-primary);
  --mg-font-family-zebra: var(--mg-font-primary);
  --mg-font-family-camel: var(--mg-font-primary);
  --mg-font-family-goat: var(--mg-font-primary);
  --mg-font-family-gorilla: var(--mg-font-primary);
  --mg-font-family-monkey: var(--mg-font-primary);
  --mg-font-family-dog: var(--mg-font-secondary);
  --mg-font-family-cat: var(--mg-font-secondary);
  --mg-font-family-rabbit: var(--mg-font-secondary);
  --mg-font-family-squirrel: var(--mg-font-secondary);
  --mg-font-family-mouse: var(--mg-font-secondary);
  --mg-font-size-lion: 2rem;
  --mg-font-size-zebra: 1.75rem;
  --mg-font-size-camel: 1.75rem;
  --mg-font-size-goat: 1.5rem;
  --mg-font-size-gorilla: 1.125rem;
  --mg-font-size-monkey: 1.125rem;
  --mg-font-size-dog: 1.125rem;
  --mg-font-size-cat: 1rem;
  --mg-font-size-rabbit: 0.875rem;
  --mg-font-size-squirrel: 0.875rem;
  --mg-font-size-mouse: 0.75rem;
  --mg-font-weight-lion: bold;
  --mg-font-weight-zebra: bold;
  --mg-font-weight-camel: bold;
  --mg-font-weight-goat: bold;
  --mg-font-weight-gorilla: bold;
  --mg-font-weight-monkey: bold;
  --mg-font-weight-dog: normal;
  --mg-font-weight-cat: normal;
  --mg-font-weight-rabbit: normal;
  --mg-font-weight-squirrel: bold;
  --mg-font-weight-mouse: normal;
  --mg-font-variant-ligatures: normal;
  --mg-font-feature-settings: normal;
  --mg-color-body: #fff;
  --mg-color-primary: #0053c0;
  --mg-color-secondary: #4294ff;
  --mg-color-tertiary: #e7f2ff;
  --mg-color-primary-rgb: hexToRGB(#0053c0);
  --mg-color-secondary-rgb: hexToRGB(#4294ff);
  --mg-color-tertiary-rgb: hexToRGB(#014ecb);
  --mg-color-text-heading-rgb: hexToRGB(#0e0e0e);
  --mg-color-info-emphasis: #014ecb;
  --mg-color-info-muted: #e6eefa;
  --mg-color-text: #0e0e0e;
  --mg-color-text-muted: #595959;
  --mg-color-text-heading: var(--mg-color-text);
  --mg-color-list-decorator-bullet: var(--mg-color-primary);
  --mg-color-list-decorator-count: var(--mg-color-text);
  --mg-color-link-focus: var(--mg-color-info-emphasis);
  --mg-color-icon-fill: var(--mg-color-primary);
  --mg-color-icon-stroke: var(--mg-color-primary);
  --mg-color-eyebrow: var(--mg-color-primary);
  --mg-background-color-body: #fff;
  --mg-button-bg-primary: var(--mg-color-primary);
  --mg-button-bg-hover-primary: #0e0e0e;
  --mg-button-font: var(--mg-font-secondary);
  --mg-button-text-primary: #fff;
  --mg-button-text-primary-hover: #fff;
  --mg-button-focus-outline-color: #014ecb;
  --mg-button-border-style: none;
  --mg-button-border-color: transparent;
  --mg-button-border-width: 0;
  --mg-button-border-color-hover: var(--mg-button-border-color);
  --mg-button-border-radius: 0;
  --mg-ratio-square: 1;
  --mg-ratio-landscape: 4/3;
  --mg-ratio-portrait: 3/4;
  --mg-ratio-widescreen: 16/9;
  --mg-ratio-ultrawide: 18/5;
  --mg-ratio-obscure: 82/35;
  --mg-ratio-ultra-panavision: 124/45;
  --mg-font-letterspacing-0: -0.05em;
  --mg-font-letterspacing-1: 0.025em;
  --mg-font-letterspacing-2: 0.05em;
  --mg-font-letterspacing-3: 0.075em;
  --mg-font-letterspacing-4: 0.15em;
  --mg-font-letterspacing-5: 0.5em;
  --mg-font-letterspacing-6: 0.75em;
  --mg-font-letterspacing-7: 1em;
  --mg-link-underline-offset-lion: 0.5625rem;
  --mg-link-underline-offset-zebra: var(--mg-size-2);
  --mg-link-underline-offset-goat: 0.4375rem;
  --mg-link-underline-offset-gorilla: 0.3125rem;
  --mg-link-underline-offset-cat: 0.1875rem;
  --mg-link-underline-offset-rabbit: 0.1875rem;
  --mg-link-underline-offset-mouse: 0.125rem;
  --mg-link-underline-offset: var(--mg-size-1);
  --mg-font-lineheight-00: 0.95;
  --mg-font-lineheight-0: 1.1;
  --mg-font-lineheight-1: 1.25;
  --mg-font-lineheight-2: 1.375;
  --mg-font-lineheight-3: 1.5;
  --mg-font-lineheight-4: 1.75;
  --mg-font-lineheight-5: 2;
  --mg-size-000: -0.5rem;
  --mg-size-00: -0.25rem;
  --mg-size-1: 0.25rem;
  --mg-size-2: 0.5rem;
  --mg-size-3: 0.75rem;
  --mg-size-4: 1rem;
  --mg-size-5: 1.25rem;
  --mg-size-6: 1.5rem;
  --mg-size-7: 1.75rem;
  --mg-size-8: 2rem;
  --mg-size-9: 3rem;
  --mg-size-10: 4rem;
  --mg-size-11: 5rem;
  --mg-size-12: 6rem;
  --mg-size-13: 7.5rem;
  --mg-size-14: 10rem;
  --mg-size-15: 15rem;
  --mg-size-16: 20rem;
  --mg-size-17: 30rem;
  --mg-content-gutter: var(--mg-size-5);
  --mg-content-gutters: calc(var(--mg-content-gutter) * 2);
  --mg-component-width-max-md: 43.75rem;
  --mg-component-width-max-lg: 51.25rem;
  --mg-component-width-max-xl: 62.5rem;
  --mg-component-width-max-xxl: 120rem;
  --mg-icon-video-timer: url(staticPathPrefixer("/svg/graphite-icon-video-timer.svg"));
  --mg-icon-video-play: url(staticPathPrefixer("/svg/graphite-icon-video-play.svg"));
}
@media screen and (min-width: 54em) {
  :root {
    --mg-font-size-lion: 3rem;
    --mg-font-size-zebra: 2.25rem;
    --mg-font-size-gorilla: 1.5rem;
  }
}
@media screen and (min-width: 54em) {
  :root {
    --mg-link-underline-offset-lion: var(--mg-size-3);
    --mg-link-underline-offset-zebra: 0.6875rem;
    --mg-link-underline-offset-gorilla: var(--mg-size-2);
  }
}

/**
 * Custom colors
 */
body {
  font-family: "Lusitana", Georgia, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

a:not([class]) {
  color: #9d2a2c;
  text-decoration: none;
  background-image: linear-gradient(to right, #9d2a2c 0, #9d2a2c 100%);
  background-position: 0 97%;
  background-repeat: repeat-x;
  background-size: 100% 0.0625rem;
  transition: background-image 0.25s ease, color 0.25s ease;
}
a:hover:not([class]), a:focus:not([class]) {
  color: #265986;
  background-image: linear-gradient(to right, #265986 0, #265986 100%);
}

.hero__text-container ol,
.content ol {
  list-style: decimal;
  padding-left: 1.25rem;
}
.hero__text-container ol > li,
.content ol > li {
  padding-left: 0.375rem;
}
.hero__text-container ol > li::marker,
.content ol > li::marker {
  color: var(--mg-color-list-decorator-count);
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: normal;
}
.hero__text-container ul:not(.carousel-slide__pagination,
.splide__list,
.quiz-question__answer-list),
.content ul:not(.carousel-slide__pagination,
.splide__list,
.quiz-question__answer-list) {
  list-style: "⬩";
  padding-left: 1.25rem;
  padding-left: 0.875rem;
}
.hero__text-container ul:not(.carousel-slide__pagination,
.splide__list,
.quiz-question__answer-list) > li,
.content ul:not(.carousel-slide__pagination,
.splide__list,
.quiz-question__answer-list) > li {
  padding-left: 0.5rem;
}

@font-face {
  font-family: "clarendon-text-pro";
  src: url("https://use.typekit.net/af/9aa147/00000000000000007735a52b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/9aa147/00000000000000007735a52b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "clarendon-text-pro";
  src: url("https://use.typekit.net/af/95075b/00000000000000007735a535/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/95075b/00000000000000007735a535/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "clarendon-text-pro";
  src: url("https://use.typekit.net/af/7e1761/00000000000000007735a538/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/7e1761/00000000000000007735a538/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "clarendon-text-pro";
  src: url("https://use.typekit.net/af/96bf40/00000000000000007735a53c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/96bf40/00000000000000007735a53c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}